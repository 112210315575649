import React from "react";
import { Box, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  FormControl, 
  DialogActions, 
  Button, 
  Alert } from "@mui/material";
import { useEffect, useState, } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { findCategoryWithCode, addCategoryAndSubCategory } from "../../services/CategoryHelper";
import { showAddCategoryDialog, getCategoryList, addCategory } from "../../store/states/CategorySlice";
import { AppDispatch, AppState } from "../../store/AppStore";
import { useTranslation } from 'react-i18next';
import { ICategory, IMasterView, ISection } from "../../../types";
import AutocompleteInput from "../../components/InputComponents/AutocompleteInput";
import { EPrimititveType } from '../../data/Constants';
import DraggableComponent from "../../components/DraggableComponent";


const AddCategoryComponent = ({ open }: {open: boolean}) => {
  const { t } = useTranslation();
  const [category, setNewCategory] = useState( '' );
  const [error, setError] = useState( '' );
  const [selectedValue, setSelectedValue] = useState<ICategory | null>( null )

  const dispatch = useDispatch<AppDispatch>();
  const product = useSelector((state: AppState) => state.procos.product);
  const masterView: IMasterView = useSelector((state: AppState) => state.category.masterView);
  const selectedSection: ISection = useSelector((state: AppState) => state.category.sectionForAddSubSection);
  const categoriesList: Array<ICategory> = useSelector((state: AppState) => state.category.categories);

  const handleClose = () => {
    setNewCategory( '' );
    setSelectedValue( null );
    setError( '' );
    dispatch( showAddCategoryDialog( false ) )
  };

  // This method called when click on add icon button or add button to add the new category on root level or sub section level
  const handleAddCategory = () => {
    if ( !category ) {
      const message = t( 'messages.category.selectCategory' );
      setError( message );
      return;
    }

    const selectedCategory: ICategory = findCategoryWithCode(categoriesList, category);
    let subSectionsId: string = `S${product.id}_${selectedCategory.sequence}`;
    const newCategoryInfo = { id: subSectionsId, name: selectedCategory.name };
    const categoryWithSubCategory: Array<ISection> | string = addCategoryAndSubCategory(masterView, selectedSection, newCategoryInfo);

    if (typeof categoryWithSubCategory === EPrimititveType.String) {
      setError(t(categoryWithSubCategory as string));
    } else {
      dispatch( addCategory( categoryWithSubCategory ) );
      handleClose();
    }
  };

  // On selection of category from dropdown, it's set the selcted category code.
  const handleChange = (selectedCategory: ICategory) => {
    setSelectedValue( selectedCategory );
    setError( '' );
    setNewCategory( selectedCategory?.code );
  };

  // This method call to get the category lit to show in the dropdown to add category
  useEffect(() => {
    if (categoriesList == undefined || categoriesList.length == 0) {
      dispatch(getCategoryList())
    }
  }, [] )

  return (
    <>
      <Dialog maxWidth={ 'xs' } open={ open } onClose={ handleClose } aria-labelledby="draggable-dialog-title" PaperComponent={ DraggableComponent } className="common-dialog-style alert-dialog dialog-content">
        <DialogTitle className=" text-left cursor-move">{selectedSection != null ? selectedSection?.name : t( 'button.addCategory' )}</DialogTitle>
        <DialogContent>
          <Box noValidate component="form"
            sx={ {
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            } }
          >
            <FormControl sx={ { mt: 2, minWidth: 120 } }>
              <AutocompleteInput data={ categoriesList != undefined && categoriesList?.length > 0 ? categoriesList : [] }
                handleChange={ handleChange }
                label={ t( 'button.searchCategory' ) }
                disabled = { false }
                value = { selectedValue }
                disableClear = { false }
              />
            </FormControl>
            {error && <Alert severity="error">
              {error}
            </Alert>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className="text-capitalize" variant="outlined" onClick={ handleClose }>{t( 'button.close' )}</Button>
          <Button className="text-capitalize" variant="contained" onClick={ handleAddCategory }>{t( 'button.addCategory' )}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddCategoryComponent;