import { Tooltip, IconButton, Box, Stack, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit';
import { IProperty, IPropertySectionProps } from '../../../types';
import { CustomButton } from '../CustomButton';
import { DynamicInputControl } from './DynamicInputControl';
import { getDefaultIcon } from '../IconTypes';
import { EPropertyType } from '../../data/Constants';

/**
 *Check the association  and return value
 * @param { IPropertyAssociation } property property associations 
 * @param { [key: string]: string | null } association the default value for the property
 * @returns { string }  return the current property association
 */
function getAssociation( property: IProperty , association: { [key: string]: string | null } ):string|null {
  const defaultValue = property.default !== null ? property.default.code : null
  return association && association[property.code] ? association[property.code] : defaultValue ;
}

/**
 * Returns a section for all Properties with required form controls
 * @param {IPropertySectionProps} props Property section props
 * @returns {React.JSX.Element} A section with passed properties as form controls
 */
export const PropertiesSection = ( props: IPropertySectionProps ) => {
  const {t} = useTranslation();

  return <fieldset className="inputFieldset w-100 h-100">
    <legend className="inputLegend">{ props.label }
      <Tooltip title={ t( 'tooltip.edit' ) }>
        <span>
          <IconButton
            className={ props.editProperties ? 'action-icon-disabled' : 'action-icon' }
            onClick={ () => props.setEditProperties( true ) }
            disabled={ props.editProperties }
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
    </legend>
    <Box className="flow">
      {props.properties.length === 0 ? 
        t( 'messages.pdm.noPropertiesFound' ) :
        <>
          {props.properties.map( ( property ) => {
            return <Stack key={ property.code } direction="row" spacing={ 1 } className={ property.type == EPropertyType.Boolean ? 'pr-1 align-center' : 'pt-1 pr-1 align-center' }>
              <Grid item sx={ {width: '95%'} }>
                <DynamicInputControl
                  property = { property } 
                  propValue={ getAssociation( property, props.associations ) } 
                  handleInputChange = { props.handleInputChange }
                  fieldsEnabled = { props.editProperties }
                />
              </Grid>
              {getDefaultIcon( props.associations, property.default, property.code, t )}
            </Stack>
          } )}
          <div className="action-button">
            {CustomButton( t( 'button.save' ), 'contained', !props.editProperties || !props.isDataUpdated, props.propertyVisibility, props.handleSave )}
            {CustomButton( t( 'button.cancel' ), 'outlined', !props.editProperties, props.propertyVisibility , props.handleCancel )}
          </div>
        </>
      }
    </Box>
  </fieldset>
}