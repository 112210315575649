import React, { useEffect, useRef, useState } from 'react';
import { Box} from '@mui/material'
import { getFeatures, getMarketAssociations, getMarkets, getWorkItems, setActiveWorkItem, 
  resetProductData, setResetDataFlag, setNewWorkItem, resetIsDataUpdated, setProductStatus } from '../../store/states/ProcosSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState, AppStore } from '../../store/AppStore';
import { DefaultWIValue, ECommonDialogType, EDataTableType, EFieldSize, ERecordLength } from '../../data/Constants'
import ButtonSection from './ButtonSection';
import AutocompleteInput from '../InputComponents/AutocompleteInput';
import { FeaturesMarketAssociation } from './FeaturesMarketAssociation';
import { DataTable } from './DataTable';
import { useNavigate } from 'react-router-dom';
import { openDialog } from '../../store/states/DialogSlice';
import { IDialogFor, IWorkItemOption } from '../../../types';
import { Trans, useTranslation } from 'react-i18next';
import TabDrawer from './TabDrawer';
import { createRows } from '../../services/DataHelperFunctions';
import { RefreshButton } from '../../components/IconTypes';
import ConfirmationDialog from '../../components/ConfirmationDialog';

/**
 * This component renders the Work Item Dropdown and Data Table which returns the MA for Products
 * @returns {JSX.Element} a  Procos component.
 */
const Procos = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [rows, setRows] = useState<readonly any[]>( [] )
  const [pageCount, setPageCount] = useState( 1 );
  const [limit, setLimit] = useState( ERecordLength.RL100 );
  const [page, setPage] = useState( 1 );
  const [confirmWiWarning, setConfirmWiWarning] = useState( { open: false, 
    title: 'dialogBox.unsavedChanges', 
    description: <></>, 
    alertFor: ECommonDialogType.HomeClickWarning }
  );

  const procos = useSelector( ( state: AppState ) => state.procos )
  const product = procos.product;
  const markets = procos.markets;
  const activeWorkItem = procos.activeWorkItem;
  const workItems = procos.workItems;
  const isDataUpdated = procos.isDataUpdated;
  const isDataReset = procos.isDataReset;
  const newWorkItem = procos.newWorkItem;
  const saveProcosCompleted = procos.saveProcosCompleted;
  const productCode = procos.product.id;

  const isFeaturesPage = window.location.href.indexOf( 'features' ) != -1;
   
  //trigger the  API to fetch the markets,markeassociation,workitems response
  //Will be triggered when creating a new work item (Saving from master) or when switching between work items
  useEffect( () => {
    //callRuleAndEffectivityAPI - false - no save action beeing performed,  call APIs to get Market, features and Market assiciation
    //callRuleAndEffectivityAPI - true - save action beeing performed. Dont call the get data APIs
    //IsSaveProcosCompleted - save procos completed and call APIs to get Market, features and Market assiciation
    if(activeWorkItem?.IsSaveProcosCompleted || !procos.callRuleAndEffectivityAPI)
    {
      const workItemId = activeWorkItem && activeWorkItem?.id != DefaultWIValue.id ? activeWorkItem.id : '';
      if ( product?.id ) {
        dispatch( getMarkets( { page: 1, limit: ERecordLength.RL100, workItemId: workItemId } ) );
        dispatch( getFeatures( { productCode: productCode, workItemNum: workItemId, includeScopeFeatures: false, page: 1, limit: ERecordLength.RL100 } ) );

      } else {
        dispatch( resetProductData() )
        navigate( '/' );      
      }
      //Call the get market association rule api after changing the WorkItem
      //!procos.callRuleAndEffectivityAPI will block calling get market association rule api while saving procos 
      if ( activeWorkItem && !procos.callRuleAndEffectivityAPI) {
        setPage( 1 );
        setLimit( ERecordLength.RL100 );
        dispatch( getMarketAssociations( getMarketRuleRequest( activeWorkItem, productCode ) ) );
      }
      }
  }, [activeWorkItem] );

  useEffect(() => {
    if(product?.id)
      dispatch( getWorkItems() );
  }, [])

  //Once Market data is filled Rows are Created
  useEffect( () => {
    if ( markets.length > 0 ) {
      setPageCount( Math.ceil( markets.length / limit ) );
      setRows( createRows( markets, 1, limit ) );
    } else {
      setRows( [] );
    }
  }, [markets] )

  //Update the rows data whenever updated the product marketAssociations
  useEffect( () => {
    if ( product?.ma != null ) {
      setRows( createRows( markets, page, limit, product.ma.updatedMarkets ) );
    }
  }, [product.ma] )

  //Reset the WorkItem after reset all the data
  useEffect( () => {
    if ( isDataReset && newWorkItem ) {
      dispatch( setResetDataFlag( false ) );
      refreshWorkItemData( newWorkItem );
    }
  }, [isDataReset] )

  //Call the get market associations after saving the data
  useEffect( () => {
    if (saveProcosCompleted) {
      dispatch( getMarketAssociations( getMarketRuleRequest( activeWorkItem, productCode ) ) );
    }

  }, [saveProcosCompleted] ) 

  //set the selected work item while changing the vlaue in workitem select
  const handleChangeWorkItem = ( value: IWorkItemOption ) => {

    if ( !isDataUpdated ) {
      refreshWorkItemData( value );
    } else {
      dispatch( setNewWorkItem( { id: value.id, name: value.name } ) );
      setConfirmWiWarning( { ...confirmWiWarning, open: true, description: getWiWarningMessage( value.name ) } )
    }
  }


  const refreshWorkItemData = ( value: IWorkItemOption ) => {
    dispatch( setNewWorkItem( null ) );
    dispatch( getWorkItems() );
    dispatch( setActiveWorkItem( { id: value.id, name: value.name } ) );
    dispatch( resetIsDataUpdated() )
  }

  //get the request payload for getMarketAsssociation Rule API 
  const getMarketRuleRequest = ( value, productId: string ) => {

    return {
      productCode: productId,
      onlyMarketRules: true,
      workItemId: value && value.name != DefaultWIValue.name ? value.id : ''
    }
  }

  //handle Records Change
  const handleRecordsChange = ( pageLimit: number ) => {
    const totalPages = Math.ceil( markets.length / pageLimit )
    setPageCount( totalPages );
    setLimit( pageLimit )
    setPage( 1 );
    setRows( createRows( markets, 1, pageLimit ) );
  }

  //To handle Page change
  const handlePageChange = ( currentPage: number ) => {
    setRows( createRows( markets, currentPage, limit, product.ma?.updatedMarkets ) );
    setPage( currentPage );
  }

  const handleSelectStatus = ( e ) => {
    dispatch( setProductStatus( e.target.value ) );
  }

  const refreshProcos = () => {
    const workItemId = activeWorkItem?.id != DefaultWIValue.id ? activeWorkItem.id : ''
    dispatch( getWorkItems() );
    dispatch( getMarketAssociations( getMarketRuleRequest( activeWorkItem, productCode ) ) );
    dispatch( getFeatures( { productCode: productCode, workItemNum: workItemId, includeScopeFeatures: false, page: 1, limit: ERecordLength.RL100 } ) );  
  }

  //calls on Refresh button click
  const handleRefresh = ()=> {
    if ( !isDataUpdated ) {
      refreshProcos()
    }else{
      dispatch( openDialog( {show: true, type: ECommonDialogType.RefreshProcos, handleClickOk: refreshProcos} ) )
    }
  }

  //This method triggers when user clicks on cancel or ok button on confirm dialog
  const onConfirmWiStatus = ( confirm: IDialogFor ) => {
    setConfirmWiWarning( { ...confirmWiWarning, open: false } );
    if ( confirm.status ) {
      dispatch( setResetDataFlag( true ) )
    } else{
      dispatch( setNewWorkItem( null ) );
    }
  }

  //Returns the work item warning message with name
  const getWiWarningMessage = ( workItemName:string ) =>{
    return <Trans i18nKey="messages.cancel.workitemWarning" values={ { workItemName: workItemName } } components={ { 1: <b /> } } />
  }


  return <>
    <Box className="d-flex h-inherit">
      <TabDrawer />
      <Box id="procos-main" className="h-inherit middle-container">
        <div className="w-100 d-flex justify-spacebetween mb-05em">
          <div className="autocomplete-div d-flex">
            <AutocompleteInput
              data={ workItems }
              handleChange={ handleChangeWorkItem }
              value={ activeWorkItem }
              label={ t( 'labels.workItem' ) }
              disableClear={ true }
              size = { EFieldSize.Small }
            />
            <RefreshButton handleRefresh = { handleRefresh } />
          </div>
          <ButtonSection />
        </div>
        {!isFeaturesPage &&
      <DataTable
        className="data-table"
        tableType={ EDataTableType.Product }
        pageCount={ pageCount }
        page={ page }
        limit={ limit }
        handlePageChange={ handlePageChange }
        handleRecordsChange={ handleRecordsChange }
        handleSelectStatus={ handleSelectStatus }
        rows={ rows }
      />}
        {isFeaturesPage && <FeaturesMarketAssociation/>}
      </Box>
    </Box>
    {confirmWiWarning.open && <ConfirmationDialog dialogInfo={ confirmWiWarning } onConfirmAction={ onConfirmWiStatus } />}
  </>
}
export default Procos;