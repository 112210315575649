import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, TextField, InputAdornment, Button} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store/AppStore';
import { RichTreeView } from '@mui/x-tree-view';
import { ICustomTreeItem, IFeature, IOption, IProcosProduct, ISelectedTreeItem } from '../../../types';
import { PropertyAssociation } from './PropertyAssociation';
import { ECommonDialogType, EFeatureFamilyType, EPropertyApplicability, InludeCodeInView } from '../../data/Constants';
import { useTranslation } from 'react-i18next';
import { openDialog } from '../../store/states/DialogSlice';
import { getFeatures} from '../../store/states/PdmSlice';
import { getSelectedItem, getSearchData, getAllItemsWithChildrenIds } from '../../services/DataHelperFunctions';
import { AddCircle, RefreshButton, RemoveCircle, RemoveCircleEnd } from '../IconTypes';
import { IReadFeatures } from '../../../types/IRequestTypes';

//Gets the Property applicability
const getPropApplicability = ( familyType: string )=>{
  if( familyType == EFeatureFamilyType.String ) {
    return EPropertyApplicability.String
  }else if( familyType == EFeatureFamilyType.Numeric ) {
    return EPropertyApplicability.Numeric
  } else {
    return EPropertyApplicability.Feature
  }
}

//sets the Tree View data
const getTreeViewData = ( product: IProcosProduct, features: IFeature[] )=>{
  const featuresData: ICustomTreeItem[] = []
  if( features ) {
    features.forEach( ( feature:IFeature )=>{
      const optionsData: ICustomTreeItem[] = []
      feature.features?.forEach( ( option: IOption )=>{
        optionsData.push( {
          id: feature.code + '.' + option.code,
          label: InludeCodeInView ? option.description + ' (' + option.code + ')' : option.description,
          type: EPropertyApplicability.Option
        } )
      } )
      featuresData.push( {
        id: feature.code,
        label: InludeCodeInView ? feature.description + ' (' + feature.code + ')' : feature.description,
        type: getPropApplicability( feature.familyType ),
        children: optionsData
      } )
    } )
  }
  return [
    {
      id: product.id,
      label: InludeCodeInView ? product.name + ' (' + product.id + ')' : product.name,
      type: EPropertyApplicability.Product,
      children: featuresData
    }
  ] as ICustomTreeItem[]

}

//Fetches all Features
const fetchAllFeatures = ( productId: string, dispatch: AppDispatch )=>{
  const payload:IReadFeatures = {
    productCode: productId, 
    includeScopeFeatures: false, 
    workItemNum: '', 
    page: 0, 
    limit: 0}
  dispatch( getFeatures( payload ) )
}

/**
 * This component renders PDM component to perform Property associations
 * @returns {JSX.Element} a  Procos component.
 */
const Pdm = (): JSX.Element => {

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [expandedItems, setExpandedItems] = useState<string[]>( [] );
  const [filteredData, setFilteredData] = useState<ICustomTreeItem[]>( [] );
  const [initialData, setInitialData] = useState<ICustomTreeItem[]>( [] );
  const [searchValue, setSearchValue] = useState<string | null>( '' );
  const [selectedItem, setSelectedItem] = useState<ISelectedTreeItem | null>( null );

  const pdm = useSelector( ( state:AppState ) => state.pdm );
  const product = useSelector( ( state: AppState ) => state.procos.product );
  const features = pdm.features; 
  const isModified =pdm.isModified;

  let selectedItemId = ''

  useEffect( ()=>{  
    if( features.length == 0 ) {
      fetchAllFeatures( product.id, dispatch );
    }
  }, [] )

  useEffect( ()=>{ //set initial treeview data
    const treeViewData = getTreeViewData( product, features );
    setInitialData( treeViewData );
    setFilteredData( treeViewData );
    setSelectedItem( { //Set Product model as selected item by default
      code: product.id,
      label: product.name + ' (' + product.id + ')' ,
      type: EPropertyApplicability.Product
    } )
  }, [features] );

  //called on search
  const handleSearch = ( event: React.SyntheticEvent, value: string ) =>{
    setSearchValue( value );
    setFilteredData( getSearchData( initialData, value?.toLowerCase() ) );
    setExpandedItems( getAllItemsWithChildrenIds( filteredData ) )
  }

  //When user wants to switch feature
  const featureChange = ( )=>{
    setSelectedItem ( getSelectedItem( filteredData, selectedItemId ) )
  }

  //if data is updated shows warning message else directly switches the Feature
  const handleSelectedItemsChange = ( event: SyntheticEvent<Element, Event>, id: string | null ) => {
    if( selectedItem?.code !== id ) {
      if( isModified ) { 
        selectedItemId = id
        dispatch( openDialog( {show: true, type: ECommonDialogType.FeatureChangeWarning, handleClickOk: featureChange } ) )
      }else{ 
        setSelectedItem ( getSelectedItem( filteredData, id ) )
      }
    }
  };

  //Update the Expanded items change
  const handleExpandedItemsChange = ( event: React.SyntheticEvent, itemIds: string[] ) => {
    setExpandedItems( itemIds );
  };

  //called when clicked on Expand all and collapse all
  const handleExpandClick = () => {
    setExpandedItems( ( oldExpanded ) =>
      oldExpanded.length === 0 ? getAllItemsWithChildrenIds( filteredData ) : [],
    );
  };

  //When user clicks on refresh button on tree view
  const refreshTreeView = ()=>{
    fetchAllFeatures( product.id, dispatch );
  }

  //Handle Refresh button click
  const handleRefresh = ()=>{
    /*If data is not modified or selected item is product, property associations call is not made. 
    therefore no changes are lost*/
    if ( !isModified || selectedItem?.code == product.id ) {
      refreshTreeView()
    }else{
      dispatch( openDialog( {show: true, type: ECommonDialogType.RefreshPdm, handleClickOk: refreshTreeView} ) )
    } 
  }

  return <div className="contents">
    <div className="leftBar">
      <Autocomplete
        id="materialSearch"
        freeSolo
        options={ [] }
        value = { searchValue ?? '' }
        className="treeSearchBar"
        data-testid="searchbar"       
        renderInput={ ( params ) => <TextField { ...params } placeholder={ t( 'labels.search' ) } className="innerTextField"
          InputProps={ { ...params.InputProps,
            startAdornment:  <InputAdornment position="start"> <SearchIcon />
            </InputAdornment>
          } }
        /> }
        onChange={ ( event,newVal )=>{
          handleSearch( event, newVal )
        } }
      />
      <div className="d-flex justify-spacebetween">
        <Button onClick={ handleExpandClick }>
          {expandedItems.length === 0 ? t( 'labels.expandAll' ) : t( 'labels.collapseAll' )}
        </Button>
        <RefreshButton handleRefresh={ handleRefresh }/>
      </div>
      <RichTreeView
        items = { filteredData }
        className="featuresTree"
        slots={ {
          expandIcon: AddCircle,
          collapseIcon: RemoveCircle,
          endIcon: RemoveCircleEnd
        } }
        expandedItems={ expandedItems }
        onExpandedItemsChange={ handleExpandedItemsChange }
        selectedItems={ selectedItem?.code ?? '' }
        onSelectedItemsChange={ handleSelectedItemsChange }
        data-testid = "tree-view"
      />
    </div>
    <div className="rightBar">
      {selectedItem && <PropertyAssociation material={ selectedItem }/> }
    </div>
  </div>  
}

export default Pdm;