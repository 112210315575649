import React, { useState } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router';
import HeaderLogin from './HeaderLogin';
import { resetIsDataUpdated, resetProductData, setActiveWorkItem, setEditMode, setProductStatus, setResetDataFlag } from '../store/states/ProcosSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../store/AppStore';
import { DefaultWIValue, ECommonDialogType, EPageNames, EFeatureStatus } from '../data/Constants';
import { resetToInitial } from '../store/states/PdmSlice';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import ConfirmationDialog from './ConfirmationDialog';
import { IDialogFor } from '../../types';

//Render the InActive Product Warning Message
const renderInActiveWarning = ( productStatus:string, t )=>{
  return <div className="rowFlex inActiveWarningMessage">
    {productStatus != EFeatureStatus.Active && <>  
      <span className="colFlex warningIcon"><WarningIcon className="warningSize"/></span> &nbsp;
      <div className="warningMessage">
        <h2 className="m-0"> {t( 'messages.inActiveProduct.header' )}</h2><span>{t( 'messages.inActiveProduct.message' )}</span>
      </div></>
    }
  </div>
}


/**
 * Renders the header which icludes the logo and user info
 * @returns {JSX.Element} the header component
 */
const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [confirmWarning, setConfirmWarning] = useState( { open: false, title: 'dialogBox.unsavedChanges', description: 'messages.cancel.homeWarning', alertFor: ECommonDialogType.HomeClickWarning } );

  const philipsLogo = '../../public/png/logo.png';

  const productStatus:string = useSelector( ( state: AppState ) => state.procos.productStatus )
  const isDataUpdated = useSelector( ( state: AppState ) => {
    if( state.procos.isDataUpdated || state.pdm.isModified || state.category.isCategoryDataModified ) {
      return true;
    } else {
      return false;
    }
  } );

  //If user click on the Philips logo in header ,user will navigate to the HomePage
  const navigateToHome = () => {
    if ( isDataUpdated ) {
      setConfirmWarning( { ...confirmWarning, open: true } );
    } else {
      dispatch( resetProductData() )
      //Navigate to Home
      navigate( EPageNames.Home, { replace: true } );
    }
  }
  
  //This method triggers when user clicks on cancel or ok button on confirm dialog
  const onConfirmStatus = ( confirm: IDialogFor ) => {
    setConfirmWarning( { ...confirmWarning, open: false } );
    if ( confirm.status ) {
       if( location.pathname.includes( EPageNames.Pdm ) ) {
        //Pdm state reset
        dispatch( resetToInitial() );
      } else {
        //procos data reset
        dispatch( setEditMode( { isEdit: false } ) );
        dispatch( setProductStatus( EFeatureStatus.Active ) );
        dispatch( resetIsDataUpdated() );
        dispatch( setActiveWorkItem( DefaultWIValue ) );
      }
      dispatch( setResetDataFlag( true ) );
      navigate( EPageNames.Home, { replace: true } );
    }
  }
  
  return <>
    <AppBar className="headerStyle root" position="static">
      <Toolbar className="toolbar">
        <Box className="toolbarSection">
          { [EPageNames.Home].indexOf( location.pathname ) < 0 ?
            <img className="zoom80 headerLogo" src={ philipsLogo } onClick={ navigateToHome } /> :
            <img className="zoom80" src={ philipsLogo } />}
        </Box>
        {renderInActiveWarning( productStatus, t )}
        <Box className="toolbarSection">
          <HeaderLogin />
        </Box>
      </Toolbar>
    </AppBar>
    {confirmWarning.open && <ConfirmationDialog dialogInfo={ confirmWarning } onConfirmAction={ onConfirmStatus } />}
  </>

  ;
};

export default Header;
