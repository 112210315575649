import { IWorkItemOption } from '../../types';

export enum EEnvironments {
    DEV = 'DEV',
    PROD = 'PROD',
    TEST = 'TEST',
    ACC = 'ACC',
}

export enum EBrowserType {
    Chrome = 'Chrome',
    Edge = 'Edge',
    Safari = 'Safari'
}

export enum ELocalStore {
    IsUnSupportedBrowserAck = 'isUnsBrowAck'
}

export enum ECellType {
    Feature = 'feature',
    Option = 'option',
    Flag = 'flag',
    Quote = 'quote',
    Reset = 'reset',
    Status = 'status'
}


export enum EDataTableType {
    Features = 'features',
    Product = 'product'
}

export enum EPageNames {
    Home = '/',
    Procos = '/procos',
    Pdm = '/pdm',
    Category = '/categories'
}

export enum ERuleType{
    Market = 'MRKT'
}

export enum EPropertyType{
    Boolean = 'BOOL',
    Numeric = 'NUM',
    String = 'STR',
    Enum = 'ENUM',
    Single = 'SNG',
    Multiple = 'MUL'
}

export enum EPropertyApplicability{
    Master = 'MSTR',
    Feature = 'FTR',
    Option = 'OPT',
    Product = 'PROD',
    String = 'STR',
    Numeric = 'NUM'
}

export enum EMasterpropertyCodes{
  AGC = 'AGC'
}

export enum EFeatureFamilyType{
  String = 'String',
  Numeric = 'Numeric',
  Feature = 'Feature'
}

export enum ECommonDialogType {
  SaveProcos = 'SaveProcos',
  Revert = 'Revert',
  Reset = 'Reset',
  WiChangeWarning = 'WorkItemWarning',
  HomeClickWarning = 'HomeClickWarning',
  FeatureChangeWarning = 'FeatureChangeWarning',
  SaveFailed = 'SaveFailed',
  SaveSuccess = 'SaveSuccess',
  SaveProcosSuccess = 'SaveProcosSuccess',
  Warning = 'Warning',
  Save = 'Save',
  UndoPropAssociationWarning = 'UndoPropAssociationWarning',
  UpdateProductStatus='UpdateProductStatus',
  UpdateFeatureStatus='UpdateFeatureStatus',
  RequestFailed = 'Failed',
  SaveSuccessDynamicMessage = 'SavedSuccessfully',
  RefreshProcos = 'RefreshProcos',
  RefreshPdm = 'RefreshPdm'
}

export enum EPropertyGroup {
  Master = 'Master',
  Product = 'Product'
}

export enum EFeatureStatus {
  Active = 'Active',
  InActive = 'InActive'
}

export enum EnumCategories {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Category = 'category',
}

export enum EApiType {
  SaveProperties = 'saveProperties',
  SaveMarketAssociations = 'saveMarketAssociations'
}

export enum EConfirmDialog {
  RemoveEmptySection = 'removeEmptySection',
  DiscardChanges = 'discardChanges',
  DeleteSection = 'deleteSection'
}

export enum EPrimititveType {
  String = 'string'
}

export const InludeCodeInView = true;

export const ExcludeColumnFilters = ['flag', 'reset']

export const TableField = {
  feature: 'feature',
  option: 'option',
  type: 'type',
  rfq: 'RfQ_',
  flag: 'flag',
  code: 'code',
  country: 'country',
  quote: 'quote',
  status:'status',
  isActive : 'isActive'
}

export const TableHeaders = {
  features: [
    { field: TableField.isActive, width: 80, cellType: ECellType.Status,align: 'center'},
    { field: TableField.feature, width: 180, cellType: ECellType.Feature },
    { field: TableField.option, width: 180, cellType: ECellType.Option },
    { field: TableField.type },
    { field: 'reset', width: 65, cellType: ECellType.Reset, align: 'center' }
  ],
  product: [
    { field: TableField.flag, width: 50, cellType: ECellType.Flag },
    { field: TableField.code, width: 150, sortable: true, align: 'center' },
    { field: TableField.country, width: 250, sortable: true, headerAlign: 'left' },
    { field: TableField.quote, width: 70, cellType: ECellType.Quote, align: 'center' }
  ]
}

export const popperModifier = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: false,
      rootBoundary: 'viewport',
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
    },
  },
];

export const PdmTabs = [
  {
    id: 'procos',
    name: 'labels.procos',
    path: EPageNames.Procos
  },
  {
    id: 'pdm',
    name: 'labels.pdm',
    path: EPageNames.Pdm
  },
  {
    id: 'categories',
    name: 'labels.categories',
    path: EPageNames.Category
  }
]
export const ProcosTabs = [
  { id: 1, name: 'labels.product', path: '/procos' },
  { id: 2, name: 'labels.option', path: '/procos/features' }
]

export const DefaultProcosTab = 'procos';

export const DefaultWIValue: IWorkItemOption = { id: '0', name: 'Master' }

export const DefaultLanguage = {
  code: 'en-US', name: 'English'
}


export const Languages = [
  { code: 'en-US', name: 'English' },
  { code: 'fr-FR', name: 'French' },
  { code: 'de-DE', name: 'German' },
  { code: 'it-IT', name: 'Italian' }
]

export const Status = ['Active','InActive'];


export const FeatureTypeFilters = [
  { value: 'String', label: 'String' },
  { value: 'Numeric', label: 'Numeric' },
  { value: 'Feature', label: 'Feature' }
];

export const DefaultAssignmentIcon = 'D';

export enum EFieldSize{
  Small = 'small',
  Large = 'large',
  Medium = 'medium'
}

export enum ERecordLength{
  RL15 = 15,
  RL25 = 25,
  RL50 = 50,
  RL100 = 100
}

export const RecordLength = [ERecordLength.RL15, ERecordLength.RL25, ERecordLength.RL50, ERecordLength.RL100];

export enum EKeyAction{
  Enter = 'Enter',
  Clear = 'clear'
}

export enum EUrlParams{
  ProductId = 'productCode'
}