
import { EApiType, ECommonDialogType, EMasterpropertyCodes, EPropertyType, TableField } from '../data/Constants';
import { ICustomTreeItem, IFeature, IFeatureData, IFeaturesAssociations, ILov, ILovDetail, IMarket, IMarketInfo, IOption, IProcosState, IProperty, IRule, ISelectedTreeItem, ProductHierachyType } from '../../types';
import { TreeViewItemId, TreeViewBaseItem } from '@mui/x-tree-view';
import { AppState } from '../store/AppStore';
import { setError } from '../store/states/ErrorSlice';
import { EnhancedStore } from '@reduxjs/toolkit';
import { openDialog } from '../store/states/DialogSlice';
import { setIsDataUpdated } from '../store/states/PdmSlice';
import { GridColDef } from '@mui/x-data-grid';
import { IPropAssociation } from '../../types/IRequestTypes';
import { t } from 'i18next';
import { resetSaveProcosCompleted } from '../store/states/ProcosSlice';

let store: EnhancedStore<AppState>

export const injectStoreHelper = ( _store: EnhancedStore<AppState> ) => {
  store = _store
}

/**
 * Map the Market associations to Features object
 * @param {IMarketInfo[]} marketAssociations All Market associations
 * @param {string} productCode ProductCode
 * @returns {IFeaturesAssociations} Feature Market associations
 */
export const setFeatureMA = ( marketAssociations: IMarketInfo[], productCode: string, productMarkets: string[] ) => {
  const featureMarketAssociations: IFeaturesAssociations = {};
  //Filter feature Associations, then loop through it to set the Feature Associations Object
  marketAssociations.filter( details => details.code != productCode ).forEach( ( marketDetails ) => {
    featureMarketAssociations[marketDetails.code] = {
      initalMarkets: marketDetails.countries,
      updatedMarkets: fetchValidMarkets( marketDetails.countries, productMarkets ),
      type: ''
    }
  } );
  return featureMarketAssociations;
}

/**
 * Returns the family type of particular feature code
 * @param {IFeatureData} features Features
 * @param {string} featureCode Feature code
 * @returns {string} feature family type
 */
export function getFeatureType( features:IFeatureData, featureCode: string ):string {
  if( features.data ) {
    const feature = features.data.find( ( ftr:IFeature ) => ftr.code === featureCode )
    if( feature ) {
      return feature.familyType;
    }
  }
  return '';
}
/**
 * To get the Feature Market Associations in a required Format
 * @param {IFeaturesAssociations} featuresMA Updated Market Associations of features
 * @param {IFeatureData} features features
 * @returns Feature Market Associations in a required rule Format for API call
 */

/**
 * To get the Feature Market Associations in a required Format
 * @param {IFeatureData} features features
 * @param {IFeaturesAssociations} featuresMA Updated Market Associations of features 
 * @param {number} productMALength product market associations length
 * @param {IProcosState} procos procos data
 * @returns {IRule[]} rules
 */
export const getFeatureMARules = ( features:IFeatureData,featuresMA:IFeaturesAssociations, productMALength: number, procos:IProcosState ) : IRule[]=>{
  const featureMARules: IRule[] = [];
  Object.keys( featuresMA ).forEach( function ( key ) {
    const featureCodes = key.split( '.' );
    const featureCode = featureCodes.length === 0 ? featureCodes[0] : featureCodes[featureCodes.length - 1];
    const isFeatureActive = Object.keys( procos.features.status.updated ).includes( key ) ? procos.features.status.updated[key] : false
    //New Association
    if ( featuresMA[key].updatedMarkets.length < productMALength && !isEqualAssociations( featuresMA[key].initalMarkets, featuresMA[key].updatedMarkets ) ) {
      featureMARules.push( {
        code: key,
        familyType:featuresMA[key].type || getFeatureType( features,featureCode ),
          countries: featuresMA[key].updatedMarkets
      } )
    } else if ( featuresMA[key].updatedMarkets.length == productMALength ) {//Delete the Rule which means send empty countries, when it needs to follow product level
      featureMARules.push( {
        code: key,
        familyType:featuresMA[key].type || getFeatureType( features, featureCode ),
        countries: []
      } )
    }
  } )
  
  return featureMARules;
}

//Method returns feature effectivity
export const getFeatureEffectivity = (features:IFeatureData,featuresMA:IFeaturesAssociations, procos:IProcosState): IEffectivity[] => {
  const featureEffectivities: IEffectivity[] = [];
  Object.keys(featuresMA).forEach(function (key){
    const hasUpdatedStatus = procos.features.status.initial[key] != procos.features.status.updated[key]
    const featureCodes = key.split( '.' );
    const featureCode = featureCodes.length === 0 ? featureCodes[0] : featureCodes[featureCodes.length - 1];
    if( hasUpdatedStatus )
    {
      featureEffectivities.push( {
        code: key,
        familyType: getFeatureType( features, featureCode ),
        isCodeActive:  procos.features.status.updated[key]
      } )
    }
  })

  return featureEffectivities;

}

//Compares the given Array of countries and returns true if both are equal else false
export function isEqualAssociations( initalMarkets: string[], updatedMarkets: string[] ) {
  const marketsUpdated = [...updatedMarkets].sort()
  const marketsInitial = [...initalMarkets].sort()
  return JSON.stringify( marketsUpdated ) === JSON.stringify( marketsInitial )
}

//To Store the data in state in a required format for page purpose
export function getPaginatedData( state, action ) {
  const newData = { ...state };
  if ( action.page === 1 ) {
    newData['data'] = {} // To reset data on every load of first page as change in recordLength might affect the functionality
  }
  if ( state.data && Object.keys( state.data ).length > 3 ) { // Delete the second entry of data element as first element is the initial load data
    delete newData['data'][Object.keys( newData.data )[1]]
  }
  newData['searchKey'] = action?.searchKey ? action.searchKey : '';
  action.data = action.data.map( ( item, index ) => ( { ...item, id: action.page + '-' + index + 1 } ) )
  newData['data'] = { ...newData['data'], [action.page]: action.data ? action.data : [] };
  newData['page'] = action.page;
  newData['totalCount'] = action.totalRecords;
  newData['limit'] = action.limit ? action.limit : 15;
  return { ...newData };
}

//To Revert the Feature Market Associations to initial Saved selections
export function revertFeaturesMA( featuresMA: IFeaturesAssociations, productMa: string[] ) {
  Object.keys( featuresMA ).forEach( ( key ) => {
    if ( featuresMA[key].initalMarkets.length == 0 ) {
      delete featuresMA[key];
    } else {
      featuresMA[key].updatedMarkets = fetchValidMarkets( featuresMA[key].initalMarkets, productMa );
    }
  } )

  return featuresMA;
}

//To Create Features in a paginated format
export function createPaginatedFeatures( featureData:IFeatureData ):IFeature[] {
  const featureRows:IFeature[] = []
  
  featureData.data.forEach( ( feature:IFeature )=>{
    if( feature.features ) {
      feature.features.forEach( ( option:IOption ) =>{
        featureRows.push(
          {
            ...option,
            id:feature.code + '.' + option.code,
            [TableField.feature]:feature.code + '.' + feature.description, 
            [TableField.option]:option.code + '.' + option.description,
            [TableField.type]: feature.familyType,
            familyType: feature.familyType,
          }
        )
      } )
    } else{
      featureRows.push( {
        ...feature,
        id:feature.code,
        [TableField.feature]:feature.code + '.' + feature.description,
        [TableField.type]: feature.familyType,
      } );
    }
  } )

  return featureRows;
}

//To remove a Market from all the Features Market association
export function removeMarketFeatureMa( featuresMa: IFeaturesAssociations, market: string, productMaLength: number ) {
  Object.keys( featuresMa ).forEach( ( key ) => {
    const index = featuresMa[key].updatedMarkets.indexOf( market );
    if( index >= 0 ) { //Market is present

      //Initial countries are not present which means no rule and this is the last element we are removing from array hence delete
      if( featuresMa[key].initalMarkets.length == 0 && featuresMa[key].updatedMarkets.length == 1 ) {
        delete featuresMa[key];
        return; 
      }
      featuresMa[key].updatedMarkets.splice( index, 1 );
    } else{ //Market is not present
      //Initial countries are not present which means no rule and option and product selections are equal so can be deleted
      if( featuresMa[key].initalMarkets.length == 0 && featuresMa[key].updatedMarkets.length === productMaLength ) {
        delete featuresMa[key];
        return; 
      }
      //Initial countries length is greater than 0 and now following Product model selections so can be made to empty array indicating rule can be deleted
      else if( featuresMa[key].initalMarkets.length > 0 && featuresMa[key].updatedMarkets.length === productMaLength ) {
        featuresMa[key].updatedMarkets = []
      }
    }
  } )
  return featuresMa;
}

//To return data object on API call success
export function handleSuccess( response, action?: EApiType ) {
  //Specific dispatches based on api responses
  if( action ) {
    switch( action ) {
      case EApiType.SaveProperties: 
        store.dispatch( setIsDataUpdated( false ) );
        store.dispatch( openDialog( { show: true, type: ECommonDialogType.SaveSuccessDynamicMessage, data:t( 'messages.submit.general' )} ) )
    }
  }
  return {
    data: response.data
  } 
}
 
//To return error object on API call failure
export function handleError( err, action?: EApiType ) {
  const error = err.response
  store.dispatch( setError( error ) )
  //Specific dispatches based on api responses
  if( action && ( action == EApiType.SaveProperties || action == EApiType.SaveMarketAssociations ) ) {
    if(action == EApiType.SaveMarketAssociations)
    {
      //Reseting SaveProcosCompleted as false as save procos failed.
      store.dispatch(resetSaveProcosCompleted(false));
    }
    store.dispatch( openDialog( { show: true, type: ECommonDialogType.SaveFailed} ) )
  }
  return {
    error: error
  } 
}

//Add a market to all Features Market associations whenmarket added at Product level
export function addMarketFeatureMa( featuresMa:IFeaturesAssociations, market: string ) {
  Object.keys( featuresMa ).forEach( key=>{
    featuresMa[key].updatedMarkets.push( market )
  } )
  return featuresMa;
}

//Get Feature Status from the API response
export function getFeatureStatus( featureData ) {
  const featureStatus = {}
  featureData.data.forEach( feature => {
    featureStatus[feature.code] = feature.isActive;
    if( feature.features ) {
      feature.features.forEach( ( option )=>{
        featureStatus[feature.code + '.' + option.code] = option.isActive;
      } )
    }
  } );
  return featureStatus
}

//This function maps the list of possible values available to enum type properties
export function preparePropertiesLov( properties: IProperty[], productHierarchyList: ProductHierachyType[], listOfValues: ILov[] ) {
  const propertiesData = JSON.parse(JSON.stringify(properties));
  propertiesData.map( ( prop:IProperty ) => {
    // This is exceptional case handled for AGC to show drop down.
    if(prop.code === EMasterpropertyCodes.AGC && prop.type === EPropertyType.String) {
      prop.type = EPropertyType.Enum;
      prop.selectionType = EPropertyType.Single;
      const listOfValues: ILovDetail[] = productHierarchyList.map((product: ProductHierachyType) => {
        return {
          code: product.ag,
          name: `${product.description}(${product.ag})`
        }
      })
      prop.listOfValues = listOfValues ? listOfValues : [];
    }
    if( prop.type == EPropertyType.Enum && prop.values) {
      const propList = listOfValues.find( ( list: ILov ) => list.code == prop.values );
      prop.listOfValues = propList ? propList.options : [];
      prop.default = prop.default ? JSON.stringify( [prop.default] ) : null;
    }
  } )
  return propertiesData;
}

export function getPMPPropertyStatus( features:IFeature[] ) {
  let isActiveProduct = false;
  features.forEach( ( feature:IFeature )=>{
    if( feature.code === 'PMP' ) {
      isActiveProduct = feature.isActive
    }
  } )
  return isActiveProduct;
}

//Create Rows for Product Table
export function createRows( markets: IMarket[], page: number, limit: number, updatedMarkets?: string[] ) {
  const rows: any[] = [];
  let count = 1;
  markets?.forEach( ( market:IMarket ) => {  
    if ( count > ( page - 1 ) * limit && count <= page * limit ) {
      rows.push( {
        id: market.code,
        [TableField.code]: market.code,
        [TableField.country]: market.name,
        [TableField.quote]: updatedMarkets && updatedMarkets.includes( market.code ) ? 'Y' : 'N'
      } )
    }
    count++;
  } )
  return rows;
}

//Formats the data to be sent in Property Association Request
export function getPropAssocRequest( material: ISelectedTreeItem ): IPropAssociation {
  if( material.featureCode && material.productCode ) { //Read and Save Associations Request for Options
    return {
      productCode: material.productCode,
      featureCode: material.featureCode,
      optionCode: material.code
    }
  }else if( material.productCode ) { //Read and Save Associations Request for Features
    return {
      productCode: material.productCode,
      featureCode: material.code
    }
  }else{ //Read and Save Associations Request for Product
    return { 
      productCode: material.code
    }
  }
}

//performs search functionality
export function getSearchData( initialData: ICustomTreeItem[], searchVal: string | null ) {
  const treeViewData = []
  if( searchVal ) { // when search value is present
    const filteredData: ICustomTreeItem[] = []
    initialData[0].children?.forEach( ( feature )=>{
      //search on options
      const optionsData = feature.children?.filter( option => option.label.toLowerCase().includes( searchVal ) ) 
      //search on Features
      if( feature.label.toLowerCase().includes( searchVal ) || optionsData?.length > 0 ) {
        filteredData.push( {...feature, children: optionsData} );
      }
    } )
    //Update the tree view data with filtered values
    treeViewData.push( {
      id: initialData[0].id,
      label: initialData[0].label,
      type: initialData[0].type,
      children: filteredData
    } )
    return treeViewData;
  } else{ // when search value is cleared
    return initialData;
  }
}

//Returns all ids of tree items To Expand all items
export function getAllItemsWithChildrenIds( filteredData: ICustomTreeItem[] ) {
  const itemIds: TreeViewItemId[] = [];
  const registerItemId = ( item: TreeViewBaseItem ) => {
    if ( item.children?.length ) {
      itemIds.push( item.id );
      item.children.forEach( registerItemId );
    }
  };
  
  filteredData.forEach( registerItemId );
  return itemIds;
}

//Find selected item in Options
export function getSelectedOption( optionData: ICustomTreeItem[], id: string, productCode: string, featureCode: string ) {
  for( const option of optionData ) {
    if( option.id === id ) { //Selected item is option
      return {
        code: option.id.split( '.' )[1],
        label: option.label,
        type: option.type,
        productCode: productCode,
        featureCode: featureCode
      }
    }
  }
  return null;
}

//Find selected item in Features for TreeView
export function getSelectedFeature( featureData: ICustomTreeItem[], id: string, productCode: string ) {
  for( const feature of featureData ) {
    if( feature.id === id ) { //Selected item is feature
      return {
        code: feature.id,
        label: feature.label,
        type: feature.type,
        productCode: productCode
      }
    }
    if( feature.children ) {
      const selectedOption = getSelectedOption( feature.children,id,productCode, feature.id )
      if( selectedOption ) {
        return selectedOption;
      }
    }
  }
  return null;
}

//Gets the Selected Item for Tree View
export function getSelectedItem( filteredData: ICustomTreeItem[], id: string | null ): ISelectedTreeItem | null {
  if( filteredData.length === 0 || !id ) {
    return null;
  } else if( id === filteredData[0].id ) { //Selected item is product
    return {
      code: filteredData[0].id,
      label: filteredData[0].label,
      type: filteredData[0].type
    }
  }else {
    if( filteredData[0].children ) {
      return getSelectedFeature( filteredData[0].children, id, filteredData[0].id )
    }
  }
  return null;
}

//To show specific columns in Toggable Columns menu
export const getTogglableColumns = ( cols: GridColDef[] ) => {
  return cols
    .filter( ( column ) => !column.field.includes( TableField.rfq ) )
    .map( ( column ) => column.field );
};


//Checks if the given market associations are part of product markets and returns the filtered array
export function fetchValidMarkets( markets: string[], productMa: string[] ) {
  const updatedMarkets: string[] = [];
  markets.forEach( ( market )=>{
    const m = productMa?.find( x=> x == market )
    if( m ) {
      updatedMarkets.push( market )
    }
  } )
  if( updatedMarkets.length == 0 ) {
    return productMa;
  }
  return updatedMarkets
}