import { handleError, handleSuccess } from '../services/DataHelperFunctions';
import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import ApiUrls from '../services/APIUrl';
import { EApiType } from '../data/Constants';
import { IReadPropAssociations, IPagination, ISavePropAssociations } from '../../types/IRequestTypes';

export const PdmApi = {

  //This method returns the Properties
  getProperties: ( action:IPagination ) => {
    return AxiosClient
      .post( ApiUrls.readProperties, action )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },

  getPropertyAssociations: ( action: IReadPropAssociations ) => {
    return AxiosClient
      .post( ApiUrls.readPropertyAssociation, action )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },

  savePropertyAssociations: ( action: ISavePropAssociations ) => {
    return AxiosClient
      .post( ApiUrls.createPropertyAssociation, action )
      .then( ( response ) => {
        return handleSuccess( response, EApiType.SaveProperties );
      } ).catch( ( err ) => {
        return handleError( err, EApiType.SaveProperties )
      } )
  },

  //This method returns the List Of Values
  getListOfValues: () => {
    return AxiosClient
      .get( ApiUrls.readLov , 
        {
          baseURL: appSettings.DisEndpoint
        } )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },

  //This method returns the List Of ProductHierarchy
  getProductHierarchyList: async () => {
    const payload: {search: string, agCodeOnly: boolean} = { search: '', agCodeOnly: true };
    try {
      const response = await AxiosClient
        .post(ApiUrls.readProductHierarchy, payload,
          {
            baseURL: appSettings.DisEndpoint
          });
      return handleSuccess(response);
    } catch (err) {
      return handleError(err);
    }
  },
}