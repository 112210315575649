import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { revertMarketAssociations, resetFeatureMA, resetIsDataUpdated, createWorkItem, createRules, createEffectivity, 
  setCallRuleAndEffectivityAPI, resetSaveProcosCompleted, setRulesFulfilled, setEffectivityFulfilled, setCreateRuleCalled, setCreateEffecivityCalled } from '../store/states/ProcosSlice';
import { IProcosProduct, IProcosState, IWorkItemOption } from '../../types/IComponentTypes';
import { AppDispatch, AppState, AppStore } from '../store/AppStore';
import { closeDialog, openDialog } from '../store/states/DialogSlice';
import DraggableComponent from './DraggableComponent';
import { DefaultWIValue, ECommonDialogType, ERuleType, EFeatureStatus } from '../data/Constants';
import { getFeatureMARules, isEqualAssociations, getFeatureEffectivity } from '../services/DataHelperFunctions';
import { IEffectivity, IEffectivityRequest, IRule, ISaveRuleRequest } from '../../types';
import { Trans, useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import { t } from 'i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const getSaveRulesRequest = async (workItem: any, product: IProcosProduct, procos: IProcosState): Promise<ISaveRuleRequest> => {
  const featuresMA = AppStore.getState().procos.features.ma;
  const productMALength = AppStore.getState().procos.product.ma?.updatedMarkets.length;
  const features = AppStore.getState().procos.features;

  let rules: IRule[] = [];
  const productRule = getProductRequest( product )
  if ( productRule ) {
    rules.push( productRule );
  }

  //get Updated Feature Market associations in Rule format
  rules = [...rules, ...getFeatureMARules( features, featuresMA, productMALength, procos )]

  return {
    productCode: product.id,
    workItemId: workItem.id,
    ruleType: ERuleType.Market,
    marketRules: rules
  }
}

const getSaveEffectivityRequest = async (workItem: any, product: IProcosProduct, procos: IProcosState): Promise<IEffectivityRequest> => {
  const featuresMA = AppStore.getState().procos.features.ma;
  const features = AppStore.getState().procos.features;

  let featureEffecivities: IEffectivity[] = [];

  //get Updated Feature Market associations in Rule format
  featureEffecivities = [...featureEffecivities, ...getFeatureEffectivity( features, featuresMA, procos )]

  return {
    productCode: product.id,
    workItemId: workItem.id,
    isProductActive: procos.productStatus == EFeatureStatus.Active ? true : false,
    features: featureEffecivities
  }
}

//return the product rules  for save Market association api
const getProductRequest = ( product: IProcosProduct ) => {
  if ( !product.ma ) {
    return null;
  }
  if ( product.ma.initalMarkets.length == 0 && product.ma.updatedMarkets.length > 0 ||
    product.ma.initalMarkets.length > 0 &&
    !isEqualAssociations( product.ma.initalMarkets, product.ma.updatedMarkets ) ) {
    return {
      code: product.id,
      familyType: '',
      countries: product.ma.updatedMarkets,
    }
  }

  return null;
}


//get Success Message with Failed Rules and Effectivities
const getSuccessMessage = ( workItem: IWorkItemOption, failedRules: string[], effectivityResult: any, isCreateRuleCalled: boolean, isCreateEffectivityCalled: boolean ) => {
  const hasFailedRule = isCreateRuleCalled && failedRules?.includes('RuleCreationFailed');
  const hasFailedEffectivity = isCreateEffectivityCalled && Object.keys(effectivityResult || {}).includes('AllEffecivityFailed');
  const isSaveFailed = isSaveProcosFailed(failedRules, effectivityResult, isCreateRuleCalled, isCreateEffectivityCalled);
  return <Box>
        {
          isSaveFailed ? <div className="d-flex"><CancelOutlinedIcon color= "error" className='icon-font-size'/>{t( 'messages.submit.error' )}</div> :
          <div className="d-flex"><CheckCircleOutlineIcon color='success' className="icon-font-size"/>
          <Trans i18nKey="messages.submit.success" values={ { workItemName: workItem.name } } components={ { 1: <b /> } } /></div>
        }
        {
          failedRules && failedRules?.length == 0 && !hasFailedRule && isCreateRuleCalled && 
          <>
            <div className="d-flex">
              <CheckCircleOutlineIcon color= "success" className='icon-font-size'/>
              <div className="text-left m-0 p-5px ">{ t( 'messages.submit.RulesSaved' ) }</div>
            </div>
          </>
        }
        {
          effectivityResult && Object.keys( effectivityResult ).length == 0 && !hasFailedEffectivity && isCreateEffectivityCalled && 
          <>
            <div className="d-flex">
              <CheckCircleOutlineIcon color= "success" className='icon-font-size'/>
              <div className="text-left m-0 p-5px ">{ t( 'messages.submit.EffectivitySaved' ) }</div>
            </div>
          </>
        }
        {failedRules && failedRules?.length > 0 &&
          <>
            <div className="d-flex">
              <CancelOutlinedIcon color= "error" className='icon-font-size'/>
              <h4 className="text-left m-0 p-5px ">{t( 'labels.failedRules' )}</h4>
            </div>
            <Box className="failed-rule d-flex" >{failedRules?.map( ( rule ) => {
              return <Box className="p-5px" key={ rule }>{ hasFailedRule ? `\u2022 ${t('labels.RuleCreationFailed')}`: `${rule}`}</Box>
              } )}
            </Box>
          </>
        }
        {effectivityResult && Object.keys( effectivityResult ).length > 0 &&
          <>
            <div className="d-flex">
              <CancelOutlinedIcon color="error" className="icon-font-size" />
              <div className="text-left m-0 p-5px">{t('messages.submit.failedEffectivities')}</div>
            </div>
            <Grid container spacing={1} className="fail-effectivity pt-1">
              {Object.keys(effectivityResult).map((key) => (
                <div className="d-flex" key={key}>
                  <span className="item">
                    {hasFailedEffectivity ? `\u2022 ${t('messages.submit.AllEffecivityFailed')}` : `\u2022 ${t(key)}`}
                  </span>
                </div>
              ))}
            </Grid>
          </>
        }
      </Box>
}

//returns the dialog title for diffrent type
const getDialogTitle = ( type: ECommonDialogType ) => {

  if ( type === ECommonDialogType.FeatureChangeWarning
    || type === ECommonDialogType.UndoPropAssociationWarning
    || type === ECommonDialogType.RefreshProcos 
    || type === ECommonDialogType.RefreshPdm ) {
    type = ECommonDialogType.Warning;
  } else if ( type === ECommonDialogType.SaveProcos) {
    type = ECommonDialogType.Save
  }

  switch ( type ) {
    case ECommonDialogType.Save:
      return t( 'dialogBox.save' )
    case ECommonDialogType.Revert:
      return t( 'dialogBox.revert' );
    case ECommonDialogType.Reset:
      return t( 'dialogBox.reset' );
    case ECommonDialogType.SaveFailed:
    case ECommonDialogType.RequestFailed:
      return t( 'dialogBox.error' );
    case ECommonDialogType.Warning:
      return <div className="d-flex"><WarningIcon className="warning-icon" /> &nbsp;
        <div>{t( 'dialogBox.unsavedChanges' )}</div></div>
    case ECommonDialogType.SaveProcosSuccess:
    case ECommonDialogType.SaveSuccessDynamicMessage:
      return t('dialogBox.success')
    default:
      return 'Dialog';
  }
}

//returns the dialog content for different type
const getDialogContent = ( param: {type: string, dialogData: any, workItem: IWorkItemOption, failedRules: string[], effectivityResult: string[], t, isCreateRuleCalled: boolean, isCreateEffectivityCalled: boolean} ) => {
  switch ( param.type ) {
    case ECommonDialogType.SaveProcos:
      return getSaveMessage( param.workItem );
    case ECommonDialogType.Revert:
      return <Trans i18nKey="messages.submit.revert" values={ { workItemName: param.workItem.name } } components={ { 1: <b /> } } />
    case ECommonDialogType.Reset:
      if ( param.dialogData.id ) {
        return t( 'messages.submit.reset' );
      } else {
        return t( 'messages.submit.resetAll' );
      }
    case ECommonDialogType.SaveProcosSuccess:
      return getSuccessMessage( param.workItem, param.failedRules, param.effectivityResult, param.isCreateRuleCalled, param.isCreateEffectivityCalled );
    case ECommonDialogType.SaveFailed:
      return  <div className='d-flex'><CancelOutlinedIcon color= "error" className='icon-font-size'/>{t( 'messages.submit.error' )}</div>;
    case ECommonDialogType.FeatureChangeWarning:
      return t( 'messages.cancel.featureChangeWarning' );
    case ECommonDialogType.UndoPropAssociationWarning:
      return t( 'messages.cancel.undoPropAssociationWarning' );
    case ECommonDialogType.RefreshProcos:
    case ECommonDialogType.RefreshPdm:
      return t( 'messages.refresh' );
    case ECommonDialogType.RequestFailed:
      return <div className='d-flex'><CancelOutlinedIcon color= "error" className='icon-font-size' />{param.dialogData}</div>
    case ECommonDialogType.SaveSuccessDynamicMessage:
      return  <div className="d-flex"><CheckCircleOutlineIcon color ="success" className="icon-font-size"/>{param.dialogData}</div>

    default:
      return 'Dialog';
  }
}

//return the product rules  for save Market association api
const getDialogActions = ( type, closeDialogWindow, handleClickOk, t ) => {
  switch ( type ) {
    case ECommonDialogType.SaveProcos:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.Revert:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.Reset:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.FeatureChangeWarning:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.UndoPropAssociationWarning:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.RefreshProcos:
    case ECommonDialogType.RefreshPdm:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    default:
      return <Button onClick={ () => handleClickOk() } >{t( 'button.ok' )}</Button>
  }
}

//returns button section for dialog for diffrent types
const getDefaultActions = ( type, closeDialogWindow, handleClickOk, t ) => {
  return <><Button className="text-capitalize" onClick={ () => closeDialogWindow() }>{t( 'button.cancel' )}</Button>
    <Button variant="contained" onClick={ () => handleClickOk( type ) } >{t( 'button.ok' )}</Button></>
}

//get the save message
const getSaveMessage = ( workItem: IWorkItemOption ) => {
  if ( workItem.name != DefaultWIValue.name ) {
    return <Trans i18nKey="messages.submit.exist" values={ { workItemName: workItem.name } } components={ { 1: <b /> } } />
  } else {
    return <Trans i18nKey="messages.submit.new" components={ { 1: <b /> } } />
  }
}

//This method check if Save rule and Save effectivity apis are failed
//Failed message will be shown if
// 1. Only save rule API called and it failed
// 2. Only save effectivity API called and it failed
// 3. Both save rule and effectivity called and both are failed
const isSaveProcosFailed = (failedRules: string[], effectivityResult: string[], isCreateRuleCalled: boolean, isCreateEffectivityCalled: boolean) => {
  const hasFailedRule = isCreateRuleCalled && failedRules?.includes('RuleCreationFailed');
  const hasFailedEffectivity = isCreateEffectivityCalled && Object.keys(effectivityResult || {}).includes('AllEffecivityFailed');
  return (!isCreateEffectivityCalled && hasFailedRule) || (hasFailedEffectivity && !isCreateRuleCalled) || (hasFailedRule && hasFailedEffectivity);
}

/**
 * This component is to show the Common dialog for different warnings and message
 * @
 * returns {JSX.Element}  a CommonDialog component 
 */
const CommonDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const dialog = useSelector( ( state: AppState ) => state.dialog )
  const procos = useSelector( ( state: AppState ) => state.procos );
  const workItem = procos.activeWorkItem;
  const product = procos.product;
  const callRuleAndEffectivityAPI = procos.callRuleAndEffectivityAPI;
  const saveProcosCompleted = procos.saveProcosCompleted;
  const effectivityResult = procos.effectivityResult;
  const isCreateRuleCalled = procos.isCreateRuleCalled; //Used to manage save status message when create rule API succeeded or failed
  const isCreateEffectivityCalled = procos.isCreateEffectivityCalled; //Used to manage save status message when create effectivity API succeeded or failed


  //Open Dialog after save the data successfully
  useEffect( () => {
    //Open the save procos status popup only if all related APIs are executed
    if ( saveProcosCompleted ) {
      dispatch( openDialog( {
        show: true,
        type: ECommonDialogType.SaveProcosSuccess
      } ) )
      dispatch(resetSaveProcosCompleted(false));
    }

  }, [saveProcosCompleted] )

  useEffect(() => {
    const saveRulesAndEfectivity = async () => {
      if (callRuleAndEffectivityAPI && workItem?.id && workItem.id !== '0') {
        dispatch(setCreateRuleCalled(false));
        dispatch(setCreateEffecivityCalled(false));
        // Save rule only if market rules are available
        const saveRuleRequest = await getSaveRulesRequest(workItem, product, procos);
        if (saveRuleRequest?.marketRules?.length > 0 && procos.productStatus === EFeatureStatus.Active) {
          dispatch(setCreateRuleCalled(true));
          dispatch(createRules(saveRuleRequest));
        } else {
          // Set the state rulesFulfilled to true
          // This is to open the save procos status pop up even though the API is not executed
          dispatch(setRulesFulfilled());
        }
  
        const effectivityRequest = await getSaveEffectivityRequest(workItem, product, procos);
        // Call effectivity only if product/features status changed
        if (effectivityRequest.features?.length > 0 || procos.isProductStatusUpdated) {
          dispatch(setCreateEffecivityCalled(true))
          dispatch(createEffectivity(effectivityRequest));
        } else {
          // Set the state effectivityFulfilled to true
          // This is to open the save procos status pop up even though the API is not executed
          dispatch(setEffectivityFulfilled());
        }
        dispatch(setCallRuleAndEffectivityAPI(false));
      }
    };
  
    saveRulesAndEfectivity();
  }, [callRuleAndEffectivityAPI]);

  //dispatch th closediaglog action to close the dialog box
  const closeDialogWindow = () => {
    dispatch( closeDialog() );
  }

  //triggers the action while click on OK button for different type.
  const handleClickOk = ( type: ECommonDialogType ) => {
    //closes the dialog
    dispatch( closeDialog() );
    //specific action on click of ok
    switch ( type ) {
      case ECommonDialogType.SaveProcos:
        //Create work item only if user editing on Master
        if(!workItem?.id || workItem.id == '0'){
          dispatch( createWorkItem( product.id ) );
        }
        else{
          dispatch(setCallRuleAndEffectivityAPI(true))
        }
        break;
      case ECommonDialogType.Revert:
        dispatch( revertMarketAssociations() )
        break;
      case ECommonDialogType.Reset:
        dispatch( resetFeatureMA( { id: dialog.data.id, type: dialog.data.featureType } ) )
        break;
      case ECommonDialogType.FeatureChangeWarning:
        dialog.handleClickOk();
        break;
      case ECommonDialogType.UndoPropAssociationWarning:
        dialog.handleClickOk();
        break;
      case ECommonDialogType.RefreshProcos:
        dialog.handleClickOk();
        dispatch( resetIsDataUpdated() )
        break;
      case ECommonDialogType.RefreshPdm:
        dialog.handleClickOk();
        break;
      default:
        dispatch( closeDialog() );
    }
  }

  return <>
    {dialog.show && <Dialog open={ dialog.show } aria-labelledby="draggable-dialog-title" PaperComponent={ DraggableComponent } className="common-dialog-style alert-dialog ">

      <DialogTitle className="header cursor-move">
      {(() => {
        if(dialog.type == ECommonDialogType.SaveProcosSuccess){
          const isSaveFailed = isSaveProcosFailed(product?.saveData?.failedRules, effectivityResult, isCreateRuleCalled, isCreateEffectivityCalled)
          return isSaveFailed ? getDialogTitle(ECommonDialogType.SaveFailed, t) : getDialogTitle(dialog.type, t);
        }
        else{
         return getDialogTitle(dialog.type, t);
        }
      })()}
      </DialogTitle>

      <DialogContent className="p-15px text-left ">
        {getDialogContent( {type: dialog.type, dialogData: dialog.data, workItem: workItem, failedRules: product?.saveData?.failedRules, effectivityResult: effectivityResult, t:t, isCreateRuleCalled: isCreateRuleCalled, isCreateEffectivityCalled: isCreateEffectivityCalled} )}
      </DialogContent>

      <DialogActions >
        {getDialogActions( dialog.type, closeDialogWindow, handleClickOk, t )}
      </DialogActions>

    </Dialog >}
  </>
}

export default CommonDialog;

