import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IFeature, IProcosState, IWorkItemOption } from '../../../types'
import { ProcosApi } from '../../api/ProcosAPi'
import { addMarketFeatureMa, createPaginatedFeatures, getFeatureStatus, getPMPPropertyStatus, removeMarketFeatureMa, revertFeaturesMA, setFeatureMA } from '../../services/DataHelperFunctions'
import { DefaultWIValue, EFeatureStatus } from '../../data/Constants'
import { IReadFeatures, IReadMarketAssociations, IReadMarkets } from '../../../types/IRequestTypes'

const initialState: IProcosState = {
  workItems: [],
  newWorkItem: null,
  activeWorkItem: null,
  isEditMode: false,
  isDataUpdated: false,
  isProductStatusUpdated: false,
  isDataReset: false,
  markets: [],
  product: {
    name: '',
    id: '',
    ma: {
      initalMarkets: [],
      updatedMarkets: [],
      type: ''
    }, //ma = Market Associations
  },
  features: {
    data: null,
    ma: {}, //ma = Market Associations,
    status:{ updated: null, initial: null}
  },
  saveData: null,
  productStatus: EFeatureStatus.Active,
  featureStatus:false,
  callRuleAndEffectivityAPI: false,
  workItemFulfilled: false, 
  rulesFulfilled: false, 
  effectivityFulfilled: false, 
  saveProcosCompleted: false,
  effectivityResult: [],
  isCreateRuleCalled: false,
  isCreateEffectivityCalled: false
}


//call the getWorkItems api and dispatch the response to the state
export const getWorkItems = createAsyncThunk( 'procos/getWorkItems', async ( ) => {
  return await ProcosApi.getWorkItems();
} )

//call the getMarkets api and dispatch the response to the state
export const getMarkets = createAsyncThunk( 'procos/getMarkets', async ( action: IReadMarkets ) => {
  return await ProcosApi.getMarkets( action );
} )

//call the getMarketAssociations api and dispatch the response to the state
export const getMarketAssociations = createAsyncThunk( 'procos/getMarketAssociation', async ( action: IReadMarketAssociations ) => {
  return await ProcosApi.getMarketAssociations( action );
} )

export const getFeatures = createAsyncThunk( 'procos/getFeatures', async ( action: IReadFeatures ) => {
  return await ProcosApi.getFeatures( action );
} )

//call create workitem api and dispatch the respose to the state
export const createWorkItem = createAsyncThunk('create/createWorkItem', async (action: string) => {
  return await ProcosApi.creatWorkItem(action);
})

//call create rule api and dispatch the respose to the state
export const createRules = createAsyncThunk('create/createRule', async (action: any) => {
  return await ProcosApi.createRules(action);
})

//call create effecivity api and dispatch the respose to the state
export const createEffectivity = createAsyncThunk('create/createEffectivity', async (action: any) => {
  return await ProcosApi.createEffectivity(action);
} )

//initialize the action to set the state variables
const ProcosSlice = createSlice( {
  name: 'procos',
  initialState,
  reducers: {
    updateProductMarketAssoctions( state, action ) {
      state.isDataUpdated = true;
      if ( action.payload.checked ) { //Checkbox is checked, add it to associations
        if ( state.product.ma ) {
          state.product.ma.updatedMarkets.push( action.payload.market )
        } else {
          state.product.ma = {
            initalMarkets: [],
            updatedMarkets: [action.payload.market],
          }
        }
        //Update Features Market associations to have the market added at Product level
        state.features.ma = addMarketFeatureMa( state.features.ma, action.payload.market )
      } else { //Unchecked checkbox hence remove it from associations
        const index = state.product.ma?.updatedMarkets.indexOf( action.payload.market )
        state.product.ma?.updatedMarkets.splice( index, 1 );
        state.features.ma = removeMarketFeatureMa( state.features.ma, action.payload.market, state.product.ma?.updatedMarkets.length )
                
      }
    },
    updateFeatureMarketAssociations( state,action ) {
      const featureKey = state.features.ma?.[action.payload.id];
      if( !state.isDataUpdated ) {
        state.isDataUpdated = true;
      }
      if( featureKey ) { //When feature is already present in associations, edit it
        if( action.payload.checked ) //checkbox is checked
        {
          state.features.ma[action.payload.id].updatedMarkets.push( action.payload.market )

          /*When initial countries length is 0 which represents it was added by user and the selections are
                     same as product maket selections, delete the association as there is no need to add rule to it */
          if( featureKey.initalMarkets.length == 0 && state.product.ma?.updatedMarkets.length === featureKey.updatedMarkets.length ) {
            delete state.features.ma[action.payload.id];
            return;
          }
        } else //checkbox is unchecked
        {
          const index = featureKey.updatedMarkets.indexOf( action.payload.market );

          /*When initial countries length is 0 which represents it was added by user and it is the last selection unchecked,
                    delete the association as there is no need to add rule to it */
          if( state.features.ma[action.payload.id].initalMarkets.length == 0 && state.features.ma[action.payload.id].updatedMarkets.length == 1 ) {
            delete state.features.ma[action.payload.id];
            if(state.features.status.updated) {
              state.features.status.updated[action.payload.id] = false;
            }            
            return; 
          }
          state.features.ma[action.payload.id].updatedMarkets.splice( index, 1 );
          if( state.features.ma[action.payload.id].updatedMarkets.length === 0 ) {
            if(state.features.status.updated) {
              state.features.status.updated[action.payload.id] = false;
            }
          }
          
        }
      } else{ //When feature is not present in associations, Add it
        //since no key is present, its always uncheck case
        const newMarkets = state.product.ma?.updatedMarkets.filter( market => market != action.payload.market )
        state.features.ma = { ...state.features.ma, 
          [action.payload.id]: {
            initalMarkets: [],
            updatedMarkets: newMarkets
          }
        }
        if(newMarkets && newMarkets.length == 0 ) {
          if(state.features.status.updated) {
            state.features.status.updated[action.payload.id] = false
          }          
        }
      }
      //Add Feature Type
      state.features.ma[action.payload.id].type = action.payload.featureType
    },
    resetFeatureMA( state, action ) {
      if( !state.isDataUpdated ) {
        state.isDataUpdated = true;
      }
      if( !action.payload.id )//RESET ALL
      {
        //loop through all ssociations and reset all.
        Object.keys( state.features.ma ).forEach( function( key ) {

          /* Initial countries 0 indicates they are selcted by user in current session and association 
                    can be deleted while reset since there is no need to create a rule as it already follows product level*/
          if( state.features.ma[key].initalMarkets.length == 0 ) {
            delete state.features.ma[key];
            return;
          }
          //Update the countries of features to Product Countries
          state.features.ma[key].updatedMarkets = [...state.product.ma?.updatedMarkets];
        } )
      } else //RESET
      {
        const featureKey = state.features.ma[action.payload.id];
        /* Initial countries 0 indicates they are selcted by user in current session and association 
                    can be deleted while reset since there is no need to create a rule as it already follows product level*/
        if( featureKey.initalMarkets.length == 0 ) {
          delete state.features.ma[action.payload.id];
          return;
        }
        //Update the countries of features to Product Countries
        state.features.ma[action.payload.id].type = action.payload.featureType;
        state.features.ma[action.payload.id].updatedMarkets = [...state.product.ma.updatedMarkets];
      }
    },
    setEditMode( state, action ) {
      state.isEditMode = action.payload.isEdit
    },
    setProduct( state, action ) {
      state.product.name = action.payload.name
      state.product.id = action.payload.id
    },
    setActiveWorkItem( state, action ) {
      state.activeWorkItem = action.payload
    },
    revertMarketAssociations( state ) {
      if( state.product.ma.updatedMarkets != state.product.ma.initalMarkets ) {
        state.product.ma.updatedMarkets = state.product.ma != null ? state.product.ma?.updatedMarkets : [];
      }
      state.features.status.updated = state.features.status ? state.features.status?.initial : null;
      state.features.ma = revertFeaturesMA( {...state.features.ma}, state.product.ma?.updatedMarkets );
      state.isDataUpdated = false;
      state.featureStatus = false
      state.isProductStatusUpdated = false;
    },
    resetIsDataUpdated( state ) {
      state.isDataUpdated = initialState.isDataUpdated;
    },
    setCallRuleAndEffectivityAPI(state, action){
      state.callRuleAndEffectivityAPI = action.payload;
    },
    setCreateRuleCalled(state, action){
      state.isCreateRuleCalled = action.payload;
    },
    setRulesFulfilled(state){
      state.rulesFulfilled = true;
      if (state.product.saveData && state.product.saveData?.failedRules){
        state.product.saveData.failedRules = [];
      }
    },
    setCreateEffecivityCalled(state, action){
      state.isCreateEffectivityCalled = action.payload;
    },
    setEffectivityFulfilled(state){
      state.effectivityFulfilled = true;
      state.effectivityResult = [];
    },
    resetSaveProcosCompleted(state, action){
      state.saveProcosCompleted = action.payload;
    },
    resetProductData( state ) {
      state.product = initialState.product;
      state.features = initialState.features;
      state.workItems = [];
    },
    setResetDataFlag( state, action ) {
      state.isDataReset = action.payload
    },
    setNewWorkItem( state, action ) {
      state.newWorkItem = action.payload
    },
    setProductStatus( state, action ) {
      state.productStatus = action.payload,
      state.isDataUpdated = true;
      state.isProductStatusUpdated = true;
    },
    setFeatureStatus( state, action ) {
      state.featureStatus = action.payload
    },
    updateFeatureStatus( state, action ) {
      state.features.status.updated = action.payload;
      state.isDataUpdated = true;
    },
    updateToInitialFeatureMA( state, action ) {
      if( action.payload ) {
        action.payload.forEach( ( key )=>{
          const featureKey = state.features.ma?.[key];
          if( featureKey ) {
            state.features.ma = { ...state.features.ma, 
              [key]: {
                initalMarkets: state.features.ma?.[key].initalMarkets,
                updatedMarkets:  state.features.ma?.[key].updatedMarkets
              }
            }
          } else{
            state.features.ma = { ...state.features.ma, 
              [key]: {
                initalMarkets: [],
                updatedMarkets:  state.product.ma?.updatedMarkets
              }
            }
          }
        } )
      }
    }

  },
  extraReducers: ( builder ) => {
    builder.addCase( getWorkItems.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        let workItemList: IWorkItemOption[] = [DefaultWIValue]
        workItemList = [...workItemList, ...action.payload.data];
        state.workItems = workItemList
      }
    } );
    builder.addCase( getMarkets.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.markets = action.payload.data
      } else {
        state.markets = initialState.markets;
      }
    } );
    builder.addCase( getFeatures.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.productStatus = getPMPPropertyStatus( action.payload.data ) ? EFeatureStatus.Active : EFeatureStatus.InActive;
        action.payload.data = action.payload.data.filter( ( feature:IFeature ) =>feature.code !== 'PMP' );
        const featuresData = createPaginatedFeatures( action.payload );
        state.features.data = featuresData;
        state.features.status.initial = getFeatureStatus( action.payload );
        state.features.status.updated = state.features.status.initial;
      }
    } );
    builder.addCase( getMarketAssociations.fulfilled, ( state, action ) => {
      state.isDataUpdated = false;
      state.isProductStatusUpdated = false;
      if ( !action.payload.error ) {
        const product = action.payload.data.find( x => x.code == state.product.id );
        // state.product.marketAssociations = product?.countries
        if ( product ) {
          state.product.ma = {
            initalMarkets: product.countries,
            updatedMarkets: product.countries
          }
        }
        //state.product.rows = addRfq(product.countries, state.product.rows)
        state.features.ma = setFeatureMA( action.payload.data, state.product.id, product.countries )
      } else {
        state.features.ma = {};
        state.product.ma = initialState.product.ma;
      }
    } );
    builder.addCase(createWorkItem.fulfilled, (state, action) => {
      if ( action.payload.data && action.payload.data.name ){
        state.callRuleAndEffectivityAPI = true;
        state.activeWorkItem = { name: action.payload.data.name, id: action.payload.data.id, IsSaveProcosCompleted: false}
        state.workItems = [...state.workItems, state.activeWorkItem]
        state.workItemFulfilled = true;
      }
    });

    builder.addCase(createRules.fulfilled, (state, action) => {
      if ( action.payload.data ) {
        state.product.saveData = action.payload.data;
        state.isDataUpdated = false;
        state.isProductStatusUpdated = false;
        //Setting this state once rules API is executed
        state.rulesFulfilled = true;
        checkSaveProcosFullFilled(state);
      }
    });

    builder.addCase(createEffectivity.fulfilled, (state, action) => {
      if ( action.payload.data ) {
        //if product state is 'inActive', createRules will not be called. 
        //Because of this setting state rulesFulfilled as true
        if(state.productStatus == EFeatureStatus.InActive){
          state.rulesFulfilled = true;
        }

        state.effectivityResult = action.payload.data.effectivityResult;
        const effectivityResult = action.payload.data.effectivityResult;
        if( Object.keys( effectivityResult ).length !== 0 ) {
          if(state.product.id in effectivityResult && effectivityResult [state.product.id].toLowerCase().includes('failed')){
            state.productStatus = state.productStatus == EFeatureStatus.Active ? EFeatureStatus.InActive : EFeatureStatus.Active;
          }
          Object.keys( effectivityResult ).forEach( ( key )=>{
            if(state.features.status.initial && state.features.status.updated) {
              if( effectivityResult[key].toLowerCase().includes( 'failed' ) && Object.keys( state.features.status.initial ).includes( key ) ) {
                state.features.status.updated[key] = state.features.status.initial[key]
              }
            }
          } )
          state.features.status.initial = state.features.status.updated;
        }
        //Setting this state once effectivity API is executed
        state.effectivityFulfilled = true;
        checkSaveProcosFullFilled(state);
      }
    });
  }
} )

//Setting state canOpenPopup to true when both Save rule and create effectivity APIs executed
//State canOpenPopup is used to show the save procos status
const checkSaveProcosFullFilled = (state: IProcosState) => {
  if(state.rulesFulfilled && state.effectivityFulfilled){
    state.saveProcosCompleted = true;
    state.rulesFulfilled = false;
    state.effectivityFulfilled = false;

    //IsSaveProcosCompleted is used to avoid calling get rule/ get market/ get effectivity APIs while saving procos
    //All these APIs must be called once save procos completed
    if (state.activeWorkItem && state.workItemFulfilled) {
      state.activeWorkItem.IsSaveProcosCompleted = true;
      state.workItemFulfilled = false;
    }    
  }
}

export const { updateToInitialFeatureMA, resetProductData, revertMarketAssociations, setProduct,
  updateProductMarketAssoctions, updateFeatureMarketAssociations,
  setActiveWorkItem, resetIsDataUpdated, setResetDataFlag, setNewWorkItem, resetFeatureMA,
  setEditMode, setProductStatus, setFeatureStatus, updateFeatureStatus, setCallRuleAndEffectivityAPI, resetSaveProcosCompleted, 
  setRulesFulfilled, setEffectivityFulfilled, setCreateRuleCalled, setCreateEffecivityCalled } = ProcosSlice.actions

export const procosSettings = ( state: IProcosState ) => state

export default ProcosSlice.reducer