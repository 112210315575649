import { Box, Card, CardContent, Grid, Pagination, Stack, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { getProductModels } from '../store/states/AppSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store/AppStore';
import { setProduct } from '../store/states/ProcosSlice';
import { ERecordLength } from '../data/Constants';
import RecordSelect from './InputComponents/RecordSelect';
import { useTranslation } from 'react-i18next';


/**
 * Renders the ProuctsList Grid's.
 * @returns {JSX.Element} a ProductPortFolio Component.
 */
const ProductPortfolio = () => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [productList, setProductList] = useState( [] );
  const [catalogPage, setCatalogPage] = useState<number>( 1 );
  const [pageCount, setPageCount] = useState<number>( 1 );
  const productModels = useSelector( ( state: AppState ) => state.app.productModels )

  // call dispatch to get the productmodel from the api.
  useEffect( () => {
    if ( !productModels.data || productModels.searchKey !== '' ) {
      dispatch( getProductModels( { page: catalogPage, limit: ERecordLength.RL15 } ) );
    }
  }, [] );


  //Here the SetProductList state is updated which gets ProductModels as props.
  useEffect( () => {
    if ( productModels.data ) {
      setCatalogPage( productModels.page );
      setProductList( productModels.data[productModels.page] )
      setPageCount( Math.ceil( productModels.totalCount / productModels.limit ) )
      if ( productModels?.searchKey !== '' ) {
        setPageCount( 1 )
      }
    } else {
      setProductList( [] );
    }
  }, [productModels] );

  //Handle Records per page Changed
  const handleRecordsChange = ( limit: number ) => {
    setCatalogPage( 1 );
    dispatch( getProductModels( { page: 1, limit: limit } ) );
  }

  const handlePageChange = ( _: React.ChangeEvent<unknown>, value: number ) => {
    setCatalogPage( value );
    if ( productModels.data[value] ) { //The page data already exists
      setProductList( productModels.data[value] )
    } else { //Make an api call if no page data found
      dispatch( getProductModels( { page: value, limit: productModels.limit } ) );
    }
  }


  //The handleSelect function navigates the page to procros page when Onlick is triggered.
  const handleSelect = ( prod ) => {
    dispatch( setProduct( { name: prod.description, id: prod.code } ) )
    navigate( '/procos' )
  };

  //To Handle Refresh button
  const handleRefresh = () => {
    dispatch( getProductModels( { page: 1, limit: productModels.limit } ) );
  };

  return <><div className="h-100">
    <RecordSelect
      handleRecordsChange={ handleRecordsChange }
      handleRefresh={ handleRefresh }
    />
    {productList.length === 0 ? <div className="noProduct-label">{t( 'messages.home.noProductFound' )}</div> :
      <Box className="list-Section list-Section-Tab::-webkit-scrollbar">
        <Grid container spacing={ 1 }
          className="list-ItemsContainer"
        >

          {productList.map( ( prod: { code: string, description: string }, index: number ) => {
            return <>
              <br />
              <Grid item className="catalog-Container" >
                <Card sx={ { border: '1px solid grey' } } >
                  <CardContent className="catalog-cardLayout">
                    <Box className="catalog-info" >
                      <Box>
                        <span key={ index }
                          className="productCatalogCardHeader"
                        >{prod.code}
                        </span>
                      </Box>
                      <Box className="catalog-productDescription">
                        <span
                          key={ index }
                          className="text-capitalize productCatalogCardHeader"
                        >{prod.description}
                        </span>
                      </Box>
                    </Box>
                    <Box className="catalog-icon" >
                      <ArrowCircleRightIcon onClick={ () => handleSelect( prod ) } sx={ { fontSize: 50 } } color="primary" />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          } )}
        </Grid>
        <br />

      </Box>
    }
    {productList && productList.length > 0 && <Stack spacing={ 2 } className="align-center p-5px" style={ { alignItems: 'center' } }>
      <Pagination size="small" color="primary" count={ pageCount } page={ catalogPage } onChange={ handlePageChange } />
    </Stack>}
  </div></>
}

export default ProductPortfolio;